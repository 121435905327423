import React, { useEffect, useId } from 'react';
import { PageSubtitle, PageTitle } from '../../atoms';

import './Heading.scss';

interface HeadingProps {
  title: string,
  subtitle?: string,
  children?: JSX.Element,
  className?: string
}

export const Default = ({
  title,
  subtitle,
  children,
  className = ''
}: HeadingProps) => {
  const id = useId();

  return (
    <div className={`sr-heading ${className}`}>
      <PageTitle aria-describedby={id}>
        {title}
      </PageTitle>
      {subtitle && (
        <PageSubtitle id={id}>
          {subtitle}
        </PageSubtitle>
      )}
      {children}
    </div>
  );
};

export const Page = ({ children, ...rest }: HeadingProps) => {
  useEffect(() => {
    document.title = `${rest.title} | StrangeRaptor`;

    return () => {
      document.title = 'Strange Raptor';
    };
  }, []);

  return (
    <Default {...rest}>
      <>
        <hr />
        {children}
      </>
    </Default>
  );
};

