import React from 'react';

import './PageSubtitle.scss';

interface PageSubtitleProps extends React.BaseHTMLAttributes<HTMLElement> {
  children: string;
  className?: string;
}

export const PageSubtitle = ({ children, className = '', ...rest }: PageSubtitleProps) => {
  return (
    <h2 className={`sr-page-subtitle ${className}`} {...rest} >
      {children}
    </h2>
  );
};