import React, { useId, useRef, useState } from 'react';
import { ReactComponent as PowerIcon} from '../../__assets/images/power_icon.svg';

import './TabWindow.scss';

interface Tab {
  label: string;
  element: JSX.Element;
}

interface TabWithId extends Tab {
  id: number;
}

interface TabsById {
  [id: number]: TabWithId 
}

interface TabWindowProps {
  tabs: Tab[];
}

export const TabWindow = ({ tabs }: TabWindowProps) => {
  const tabPanelId = useId();
  const selectedTabId = useId();

  const fullscreenRef = useRef<HTMLInputElement>(null);
  const [currentTab, setTab] = useState(-1);
  const [fullscreen, setFullscreen] = useState(false);

  const tabsWithId: TabWithId[] = tabs.map((item, index) => ({ ...item, id: index }));
  const tabsById: TabsById = tabsWithId.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});

  const toggleFullscreen = () => {
    if (fullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    } else {
      if (fullscreenRef?.current) {
        fullscreenRef.current.requestFullscreen();
        setFullscreen(true);
      }
    }
  };

  const renderButtons = () => {
    const powerOff = currentTab === -1;
    return (
      <div className='sr-tab-window__buttons'>
        <button 
          className={`sr-tab-window__button sr-tab-window__power ${powerOff ? '-off' : '-on'}`} 
          onClick={() => {
            if (powerOff) {
              setTab(0);
            } else {
              setTab(-1);
            }
          }}>
          <PowerIcon aria-hidden />
        Turn {powerOff ? 'on' : 'off'}
        </button>
        <button className='sr-tab-window__button' onClick={toggleFullscreen}>
          Fullscreen
        </button>
      </div>
    );
  };

  const renderSelectedWindow = () => {
    if (currentTab < 0) {
      return (
        <div className='sr-tab-window__no-content'>
          <p>NO CONTENT</p>
        </div>
      );
    }

    return tabsById[currentTab].element;
  };

  return (
    <div className='sr-tab-window'>
      <ul role='tablist' className='sr-tab-window__tabs'>
        {tabsWithId.map((item) => {
          const selected = currentTab === item.id;
          return (
            <li
              role='tab'
              aria-controls={tabPanelId}
              aria-selected={selected}
              {...( selected ? { id: selectedTabId } : {})}
              className={`sr-tab-window__tab ${selected ? '-selected' : ''}`}
              key={item.id}
            >
              <button onClick={() => setTab(item.id)}>{item.label}</button>
            </li>
          );
        })}
      </ul>
      <div
        ref={fullscreenRef}
        id={tabPanelId}
        {...(currentTab > -1 ? { 'aria-labelledby': selectedTabId } : { 'aria-label': 'No tab selected.'})}
        className='sr-tab-window__view-window'
        {...(fullscreen && { 'data-fullscreen': fullscreen })}>
        <button className='sr-tab-window__button' onClick={toggleFullscreen}>
          Fullscreen
        </button>
        {renderSelectedWindow()}
      </div>

      {renderButtons()}
    </div>
  );
};