import React from 'react';
import { Heading, LinkCard } from '../../molecules';
import { MEDIA_LINKS } from '../../__constants/constants';

import './Links.scss';


export const Links = () => {
  return (
    <div className='sr-links-page'>
      <Heading.Page 
        title='Links'
        subtitle="Other places where you can find me"
      />
      <div className='sr-links-page__link-cards-container'>
        {MEDIA_LINKS.map(({ title, links}) => (
          <LinkCard
            key={title}
            title={title}
            links={links}
          />
        ))}
      </div>
    </div>
  );
};