import React from 'react';
import { DonateKoFi, RouterAnchor } from '../../atoms';
import { NAVBAR_PAGE_INFO } from '../../__constants/constants';

import './Navbar.scss';

export const Navbar = () => {
  return (
    <nav className='sr-navbar'>
      <div className='sr-navbar__page-links'>
        {Object.entries(NAVBAR_PAGE_INFO).map(([key, page]) => (
          <RouterAnchor
            key={key}
            {...page}
          />
        ))}
      </div>
      <DonateKoFi className='sr-navbar__donate-button' />
    </nav>
  );
};