import React from 'react';
import { Link } from 'react-router-dom';

import './RouterAnchor.scss';

interface RouterAnchorProps {
  path: string;
  label?: string;
  className?: string;
}

export const RouterAnchor = ({
  path,
  label = '',
  className = ''
}: RouterAnchorProps) => {
  return (
    <Link to={path} className={`sr-router-anchor ${className}`}>
      {label}
    </Link>
  );
};