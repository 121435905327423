import React from 'react';
import { Heading } from '../../molecules';

export const About = () => {
  return (
    <div className='sr-about-page'>
      <Heading.Page
        title='About'
        subtitle='A little bit of information about myself'
      />
      <p>
        Howdy! My name is Edwin and I am a Domain Engineer with a passion for art, animation, programming and video games.
      </p>
    </div>
  );
};