import React from 'react';

import './DonateKoFi.scss';

interface DonateKoFiProps {
  className?: string;
}

export const DonateKoFi = ({ className = '' }: DonateKoFiProps) => {
  return (
    <a className={`sr-donate ${className}`} aria-label='Donate Ko-Fi' href='https://ko-fi.com/strangeraptor/' >
      <p>Donate</p>
      <img src="https://storage.ko-fi.com/cdn/cup-border.png" alt='Ko-Fi' />
    </a>
  );
};
