import React from 'react';

import './Footer.scss';

export const Footer = () => {
  return (
    <footer className='sr-footer'>
      Site created using React.js
      <hr />
      Strange Raptor
      <hr />
      2024
    </footer>
  );
};