import React from 'react';
import { Navbar } from '../../molecules/Navbar';
import { Outlet } from 'react-router-dom';
import { Footer } from '../../atoms';
import { Header } from '../../molecules';

import './Root.scss';

export const Root = () => {
  return (
    <>
      <Navbar/>
      <Header/>
      <main className='sr-main-body'>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};