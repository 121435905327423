import React from 'react';

import './Link.scss';

interface LinkProps {
  to: string;
  children?: string;
  className?: string;
}

export const Link = ({
  to,
  children,
  className = ''
}: LinkProps) => {
  return (
    <a href={to} className={`sr-link ${className}`}>
      {children}
    </a>
  );
};