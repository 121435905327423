import React from 'react';

import './PageTitle.scss';

interface PageTitleProps extends React.BaseHTMLAttributes<HTMLElement> {
  children: string;
  id?: string;
  className?: string;
}

export const PageTitle = ({ children, className = '', ...rest }: PageTitleProps) => {
  return (
    <h1 className={`sr-page-title ${className}`} {...rest} >
      {children}
    </h1>
  );
};