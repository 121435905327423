import React from 'react';
import { Heading } from '../../molecules';

import './OpenFortress.scss';


export const OpenFortress = () => {
  return (
    <div className='sr-openfortress-page'>
      <Heading.Page
        title="Open Fortress Server Rules"
        subtitle="Please read and follow them, thank you!"
      />

      <ol>
        <li>
          Racism, Homophobia, Transphobia or discrimination of any kind is <u>NOT</u> tolerated on any of these servers.
          Even if it is just for comedic reasons. 
        </li>
        <li>
          Be respectful of one another 
        </li>
        <li>
          Please refrain from NSFW sprays. This isn&apos;t a hard rule but I would very much appreciate it if you did avoid NSFW sprays.
          (if you wouldn&apos;t show your boss, maybe don&apos;t show it on the server)
        </li>
        <li>
          If someone is being annoying, vote kick is there for a reason. (let democracy handle it) 
        </li>
        <li>
          Have fun. You are not permitted to not have fun. This is totally a 100% real rule. No not having fun. Illegal.
        </li>
      </ol>

      <hr />

      <p className='sr-openfortress-page__emojis'>🥳🥳🥳</p>
    </div>
  );
};