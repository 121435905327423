import React from 'react';
import { Heading } from '../../molecules';
import { TabWindow } from '../../atoms';

import './Projects.scss'; 
import { PROJECT_TABS } from '../../__constants/constants';


export const Projects = () => {
  return (
    <div className='sr-projects-page'>
      <Heading.Page
        title='Projects'
        subtitle='a couple interactive projects I&apos;ve made that you can mess with here'
      />

      <TabWindow tabs={PROJECT_TABS} />
    </div>
  );
};