import React from 'react';
import { LinkCardProps } from '../molecules/LinkCard/LinkCard';

export const NAVBAR_PAGE_INFO = {
  ABOUT: {
    path: '/about',
    label: 'About'
  },
  LINKS: {
    path: '/links',
    label: 'Links'
  },
  OPEN_FORTRESS: {
    path: '/open-fortress',
    label: 'Open Fortress'
  },
  PROJECTS: {
    path: '/projects',
    label: 'Projects'
  }
};

export const PAGE_INFO = {
  _ROOT: {
    path: '/',
    label: ''
  },
  ...NAVBAR_PAGE_INFO
};

export const MEDIA_LINKS: LinkCardProps[] = [
  {
    title: '🖊️ Art',
    links: [
      {
        to: 'https://twitter.com/Strange_Raptor',
        label: 'Twitter / X'
      },
      {
        to: 'https://strangeraptor.newgrounds.com/',
        label: 'Newgrounds'
      },
      {
        to: 'https://www.instagram.com/strange_raptor/',
        label: 'Instagram'
      },
      {
        to: 'https://bsky.app/profile/strangeraptor.bsky.social',
        label: 'Bluesky'
      },
      {
        to: 'https://www.deviantart.com/strangeraptor',
        label: 'DeviantArt'
      }
    ]
  },
  {
    title: '🎬 Video',
    links: [
      {
        to: 'https://www.twitch.tv/strange_raptor',
        label: 'Twitch.tv'
      },
      {
        to: 'https://www.youtube.com/c/StrangeRaptorOriginal',
        label: 'Youtube'
      },
      {
        to: 'https://tiktok.com/@strange_raptor',
        label: 'Tiktok'
      },
    ]
  },
  {
    title: '🎶 Audio',
    links: [
      {
        to: 'https://soundcloud.com/sr59',
        label: 'Soundcloud'
      },
      {
        to: 'https://sr59.bandcamp.com',
        label: 'Bandcamp',
      }
    ]
  },
  {
    title: '🖥️ Code',
    links: [
      {
        to: 'https://github.com/StrangeRaptor',
        label: 'Github'
      }
    ]
  },
  {
    title: '💸 Merch / Support Me',
    links: [
      {
        to: 'https://ko-fi.com/strangeraptor/',
        label: 'Ko-fi'
      },
      {
        to: 'https://www.teepublic.com/user/strangeraptor',
        label: 'Teepublic'
      },
      {
        to: 'https://www.redbubble.com/people/StrangeRaptor/shop',
        label: 'Redbubble'
      }
    ]
  }
];

export const PROJECT_TABS = [
  {
    label: 'Game Engine Test',
    element: <iframe src='/apps/game-engine-test' />
  },
  {
    label: 'Monster Encounter',
    element: <iframe src='/apps/monster-encounter' />
  },
  {
    label: 'Text Adventure',
    element: <iframe src='/apps/text-adventure' />
  },
  {
    label: 'Tile Based',
    element: <iframe src='/apps/tile-based' />
  },
  {
    label: 'Value Guesser',
    element: <iframe src='/apps/value-guesser-compiled.html' />
  },
  {
    label: 'Idea Generator',
    element: <iframe src='/apps/idea-generator.html' />
  }
];