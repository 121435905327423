import React, { useId } from 'react';
import { Link } from '../../atoms';

import './LinkCard.scss';

interface Links {
  to: string;
  label: string;
}

export interface LinkCardProps {
  title: string;
  links: Links[];
  className?: string;
}

export const LinkCard = ({
  title,
  links,
  className = ''
}: LinkCardProps) => {
  const id = useId();

  return (
    <div className={`sr-link-card ${className}`}>
      <h3 id={id} aria-label={`${title} links`}>{title}</h3>
      <hr />
      <ul className='sr-link-card__links-container' aria-labelledby={id}>
        {links.map(({ to, label }) => (
          <li key={label}>
            <Link to={to}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};