import React from 'react';
import { Heading } from '../Heading';
import PfpImage from '../../__assets/images/pfp.gif';

import './Header.scss';

export const Header = () => {
  return (
    <header className='sr-header'>
      <img className='sr-header__pfp-image' src={PfpImage} aria-hidden />
      <Heading.Default
        title='Edwin / Strange Raptor / sr59'
        subtitle='Certified code ape of the apocalypse'
        className='sr-header__heading'
      />
    </header>
  );
};